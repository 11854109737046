// EVERYTHING YOU PUT IN HERE WILL BE IMPORTED ON ALL STYLSHEETS
// SO BE CAREFULL NOT TO PUT REAL CSS, ONLY VARIABLES AND MIXINS

//////////////////////////
//     BREAKPOINTS      //
//////////////////////////

$xxs: 320;
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1300;
$mxl: 1400;
$xl: 1441;
$xxl: 1680;

//////////////////////////
//       SPACINGS       //
//////////////////////////

$slice-spacing: 84px;

//////////////////////////
//        COLORS        //
//////////////////////////

$white: #ffffff;
$black: #000000;
$mystic-gray: #e7edf1;
$fog: #dacdff;
$nepal: #92a0c4;
$blue-chalk: #e6e0ff;
$chambray: #344b80;
$bay-blue: #1d1b84;

$waikawa-gray: #4e6294;
$pastel-green: #13bb70;
$magic-mint-green: #abedc6;
$white-ice: #effdf6;
$lily-white: #edfaff;
$magnolia: #f6f0ff;

$flame-pea: #dd6136;
$tuscany: #cc5d34;
$gin-fizz-yellow: #fff9e1;
$selago: #f6fafe;
$anakiwa: #80ebff;
$blue-haze: #c8cee1;
$bittersweet: #ff6464;
$input-purple: #5857a2;
$zircon: #f2f4ff;
$link-water: #eaf2fa;
$governor-bay: #302e9a;
$waikawa-gray: #5e709d;
$cornflower-blue: #e9f0f8;
$bay-of-many: #2f2e8b;
$deep-koamaru: #151478;
$cobalt: #014fb0;
$cobalt-dark: #01489e;
$mountain-meadow: #11a865;
$light-mountain-meadow: #60796d;
$salem: #0f8d55;
$bunting: #1a154e;
$black-dark-mode: #171724;
$blue-dark-mode: #212134;
$red-500: #d72e23;
$navyblue-120: #1d1b84;

// Blue
$blue-100: #f6f6ff;
$blue-200: #e9e9ff;
$blue-300: #dadaff;
$blue-400: #bbbbff;
$blue-500: #635cff;
$blue-600: #4945ff;
$blue-700: #322fc1;
$blue-800: #292875;
$blue-900: #1c1c4e;
$blue-1000: #040a44; // Not in scale

$scooter-blue: $blue-500;

// Dark Blue
$dark-blue-100: #181826;
$dark-blue-200: #161838;

// Purple
$purple-100: #f9f5ff;
$purple-200: #f1e7ff;
$purple-300: #e7d6ff;
$purple-400: #d4b2ff;
$purple-500: #ac56f5;
$purple-600: #9739de;
$purple-700: #662897;
$purple-800: #432460;
$purple-900: #2d1940;

$jacksons-purple: #1e1d80;
$purple-heart: #4e26e0;
$royal-blue: #7e43e6;

// Yellow
$yellow-100: #fff5ed;
$yellow-200: #ffe7d1;
$yellow-300: #ffdbb9;
$yellow-400: #f8c07c;
$yellow-500: #e4a33f;
$yellow-600: #d78400;
$yellow-700: #925a0f;
$yellow-800: #4c321d;
$yellow-900: #2e1f13;

$golden-yellow: #fee895;
$golden-orange: #f6b73e;

// Green
$green-100: #effcf0;
$green-200: #d5f2d7;
$green-300: #c8ebca;
$green-400: #89d890;
$green-500: #2caa49;
$green-600: #00842d;
$green-700: #006f25;
$green-800: #005725;
$green-900: #142919;

// Grey
$grey-100: #f6f6f9;
$grey-200: #eaeaef;
$grey-300: #dcdce4;
$grey-400: #c0c0cf;
$grey-500: #a5a5ba;
$grey-600: #8e8ea9;
$grey-700: #666687;
$grey-800: #4a4a6a;
$grey-900: #32324d;

$neutral-150: #eaeaef;
$neutral-100: #f6f6f9;
$neutral-150: #eaeaef;
$neutral-200: #dcdce4;
$neutral-300: #c0c0cf;
$neutral-500: #8e8ea9;
$neutral-600: #666687;
$neutral-800: #32324d;
$neutral-0: #fff;
$purple-anemone: #956fff;
$lavender-800: #361793;
$lavender-500: #8c4bff;
$lavender-300: #c193ff;
$lavender-1000: #0e0141;
$cyan-200: #9ae7f7;
$selection-color-background: default;
$selection-color: default;
$danger-100: #fcecea;
$danger-200: #f5c0b8;
$danger-600: #d02b20;
$primary-100: #f0f0ff;
$primary-200: #d9d8ff;
$primary-600: #4945ff;
//////////////////////////
//         FONTS        //
//////////////////////////

// prettier-ignore

$poppins: 'Poppins', sans-serif;
$monaco: 'Monaco', sans-serif;
$inter: 'Inter', sans-serif;

$title-extra-large: 53px;
$title-extra-large-mb: 43px;
$title-large: 43px;
$title-large-m: 41px;
$title-large-mb: 33px;
$title-medium: 33px;
$title-medium-mb: 23px;
$title-small: 21px;
$title-small-mb: 17px;
$title-smaller: 17px;
$title-smaller-mb: 13px;

$text-large: 21px;
$text-large-mb: 17px;
$text-medium: 17px;
$text-medium-mb: 17px;
$text-small: 15px;
$text-small-mb: 15px;
$text-smaller: 13px;
$text-smaller-mb: 13px;
$text-extra-small: 12px;
$text-legend: 11px;
$text-extra-small-card: 9px;

$label: 13px;
$label-mb: 11px;
$label-large: 17px;

$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

//////////////////////////
//        SIZES        //
//////////////////////////

$banner-mobile-height: 38px;
$banner-desktop-height: 53px;

//////////////////////////
//        MIXINS        //
//////////////////////////

@mixin breakpoint($point) {
  @media (min-width: #{$point}px) {
    @content;
  }
}

@mixin boxShadow() {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
}

@mixin cards() {
  box-shadow: 0px 2px 10px 0px rgba(117, 141, 166, 21.42%);
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  text-decoration: none;
  overflow: hidden;
}

@mixin navBackground() {
  background-color: $white;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid $zircon;
}

@mixin inputs() {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
  background-color: $white;
  border-radius: 6px;
}

//////////////////////////
//        EASING        //
//////////////////////////
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
